class Shop {

    bestellungAktualisieren() {
        let shopBestellung = document.querySelector('textarea[name="fields[bestellung]"]');
        let shopAnzahl = document.querySelectorAll('.warenkorb-seite__buch-input');
        if (shopBestellung) {
            shopBestellung.value = '';
            let summe = 0;
            let bestellungArray = [];
            let i;
            
            for (i = 0; i < shopAnzahl.length; i++) {
                if (shopAnzahl[i].value != 0 && shopAnzahl[i].getAttribute(`data-produkt`) != null) {
                    bestellungArray.push(
                        {
                            'titel': shopAnzahl[i].getAttribute(`data-produkt`),
                            'kategorie': shopAnzahl[i].getAttribute(`data-kategorie`),
                            'einzelpreis': shopAnzahl[i].getAttribute(`data-einzelpreis`),
                            'anzahl': shopAnzahl[i].value
                        }
                    );
                }
            }
    
            for (i = 0; i < bestellungArray.length; i++) {
                let preis = parseFloat(bestellungArray[i].einzelpreis);
                let anzahl = parseInt(bestellungArray[i].anzahl);
                shopBestellung.value += `${anzahl} × ${bestellungArray[i].titel} (${bestellungArray[i].kategorie}) zu je CHF ${preis.toFixed(2)} \n`;
                summe += preis * anzahl;
                console.log(summe);
            }
            if (summe > 0) {
                shopBestellung.value += (' \nSumme: CHF ' + summe.toFixed(2));
            } else {
                shopBestellung.value = '';
            }
        
            shopBestellung.parentNode.dataset.replicatedValue = shopBestellung.value;
        }
    }

}