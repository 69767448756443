class Warenkorb {

    constructor(listener = false) {
        this.warenkorbMap = new Map();
        this.listener = listener;

        this.#warenkorbCookieLesen();
    }

    initBuch() {
        const listenerDiv = document.querySelector(this.listener);
        if (listenerDiv) {  
            listenerDiv.addEventListener('click', () => {
                const buchId = listenerDiv.getAttribute('data-buch-id');
                this.#warenkorbPositionPlusMinus(buchId, 1);
            });
        } else {
            console.warn(`Element for selector ${this.listener} not found.`);
        }
    }

    initShop() {
        const listenerDiv = document.querySelector(this.listener);
        if (listenerDiv) {  
            listenerDiv.addEventListener('click', (evt) => {
                if (evt.target.className == 'shop__buch-warenkorb') {
                    const buchDiv = evt.target.closest('.shop__buch');
                    const buchId = buchDiv.getAttribute('data-buch-id');
                    const anzahl = parseInt(buchDiv.querySelector('.shop__buch-input').value);
                    this.#warenkorbPositionPlusMinus(buchId, anzahl);
                }
            });
        } else {
            console.warn(`Element for selector ${this.listener} not found.`);
        }
    }

    initWarenkorb() {
        this.#buchPreisanzeigeAktualisieren();
        this.#warenkorbTotal();
        const listenerDiv = document.querySelector(this.listener);
        if (listenerDiv) { 
            listenerDiv.addEventListener('click', (evt) => {
                if (evt.target.className == 'warenkorb-seite__buch-loeschen' || evt.target.closest('.warenkorb-seite__buch-loeschen')) {
                    const buchDiv = evt.target.closest('.warenkorb-seite__buch');
                    const buchId = buchDiv.getAttribute('data-buch-id');
                    this.#warenkorbPositionLoeschen(buchId);
                }
            });
        } else {
            console.warn(`Element for selector ${this.listener} not found.`);
        }

        document.addEventListener('warenkorbAnzahlEvent', (evt) => {
            console.log('warenkorbAnzahlEvent');
            this.#buchPreisanzeigeAktualisieren();
            this.#warenkorbTotal();
        });
    }

    // https://github.com/js-cookie/js-cookie
    #warenkorbCookieLesen() {
        // Cookie lesen
        const cookie = Cookies.get('EDULINO_WARENKORB');
        if (cookie != undefined) {
            const array = JSON.parse(cookie);
            array.forEach(element => {
                this.warenkorbMap.set(element[0], element[1]);
            });
            this.#warenkorbSymbolAktualisieren();
        }
    }

    #warenkorbCookieSchreiben() {
        // Map zu JSON
        const json = JSON.stringify(Array.from(this.warenkorbMap.entries()));
        // Cookie schreiben
        Cookies.set('EDULINO_WARENKORB', json, { expires: 7, path: '/' })
        this.#warenkorbSymbolAktualisieren();
    }

    #warenkorbSymbolAktualisieren() {
        const warenkorbZahl = document.querySelector('.warenkorb__zahl');
        let anzahl = 0;
        this.warenkorbMap.forEach(warenkorbPosition => {
            anzahl += parseInt(warenkorbPosition);
        });
        warenkorbZahl.innerText = anzahl;

        console.log('#warenkorbSymbolAktualisieren', this.warenkorbMap.size);
    }

    #buchPreisanzeigeAktualisieren() {
        let buchDivs = document.querySelectorAll('.warenkorb-seite__buch');
        for (let buchDiv of buchDivs) {
            const inputDiv = buchDiv.querySelector('.warenkorb-seite__buch-input');
            let buchAnzahl = inputDiv.value;
            const einzelpreis = inputDiv.dataset['einzelpreis'];
            let gesamtpreis = (einzelpreis * buchAnzahl).toFixed(2);
            let gesamtpreisDiv = buchDiv.querySelector('.warenkorb-seite__buch-preis');
            gesamtpreisDiv.dataset['gesamtpreis'] = gesamtpreis;
            gesamtpreisDiv.innerText = 'CHF ' + gesamtpreis;
            console.log(gesamtpreis)
        }
        this.#warenkorbTotal();
        this.#warenkorbAktualisieren('.warenkorb-seite__buch--buch', '.warenkorb-seite__buch-input');
    }

    /** 
     * Entfernt die Produkte im Warenkorb inklusive der Spaltenüberschriften und der Summe
    */
    warenkorbSeiteLeeren() {
        let buecher = document.querySelectorAll('.warenkorb-seite__buch--buch');
        for (let buchElement of buecher) {
            buchElement.remove();
        }
    }

    #warenkorbAktualisieren(buch, input) {
        const buecher = document.querySelectorAll(buch);
        for (let buchElement of buecher) {
            const anzahl = parseInt(buchElement.querySelector(input).value);
            let buchId = buchElement.dataset.buchId;
            if (Number.isInteger(anzahl)) {
                console.log('anzahl', anzahl);
                this.warenkorbMap.set(buchId, anzahl);
            }
        }
        this.#warenkorbCookieSchreiben();
    }

    #warenkorbPositionPlusMinus(produkt, plusMinus) {
        let anzahlAlt = 0;
        if (this.warenkorbMap.has(produkt)) {
            anzahlAlt = this.warenkorbMap.get(produkt);
        }
        let anzahlNeu = anzahlAlt + plusMinus;
        if (anzahlNeu > 0) {
            this.warenkorbMap.set(produkt, anzahlNeu);
        } else {
            this.#warenkorbPositionLoeschen(produkt);
        }
        this.#warenkorbCookieSchreiben();
    }

    warenkorbLoeschen() {
        console.log('warenkorbLoeschen');
        this.warenkorbMap.clear();
        this.#warenkorbCookieSchreiben();
    }

    #warenkorbTotal() {
        console.log('#warenkorbTotal');
        const preisTotalDiv = document.querySelectorAll('.warenkorb-seite__buch-preis--buch');
        const gesamtpreisData = 'gesamtpreis';
        const totalAnzeigen = document.querySelectorAll('.warenkorb-seite__total--buch');

        let preisTotal = 0;
        for (let buchElement of preisTotalDiv) {
            if (Number.isInteger(parseInt(buchElement.dataset[gesamtpreisData]))) {
                preisTotal += parseInt(buchElement.dataset[gesamtpreisData]);
            }
        }
        for (let totalAnzeige of totalAnzeigen) {
            totalAnzeige.innerText = 'CHF ' + preisTotal.toFixed(2);
        }
    }

    #warenkorbPositionLoeschen(buchId) {
        console.log('#warenkorbPositionLoeschen', buchId);
        if (this.warenkorbMap.has(buchId)) {
            this.warenkorbMap.delete(buchId);
            this.#warenkorbCookieSchreiben();
            document.querySelector(`.warenkorb-seite__buch[data-buch-id="${buchId}"]`).remove();
        }
        this.#buchPreisanzeigeAktualisieren();
        if (this.warenkorbMap.size == 0) {
            document.querySelector('.warenkorb-seite__kontaktformular').remove();
        }
    }
}
